import React, { useEffect, useRef, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import axios from "axios"
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import { ServerUrl } from "./../config"
import "./index.scss"
import 'react-toastify/dist/ReactToastify.css';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
axios.defaults.timeout = 1000 * 60;

const App = () => {

    const [messageList, setMessageList] = useState([])
    const [state, setState] = React.useState({
        isRecording: false,
        isBlocked: false
    });
    const [layer, setLayer] = React.useState(0)
    const audioRef = useRef(null)
    const [promptList, setPromptList] = useState([])
    const [voice, setVoice] = useState(true)
    const [language, setLanguage] = useState({ label: "English", value: "en" })

    let options = [
        { value: "", label: '...' },
        { value: "Let's make an exercise. I will be a mom and you are my 5 year old kid. You will have a conversation with me where you absolutely want to have me buy you a random toy and I have to resist. At the end of the conversation, you will produce a 10 lines sumary on how I did and what I could improve.", label: 'teddy' },
        { value: "Let's make an exercise. I will be a dad and you are my 14 year old kid. You will have a conversation with me where you absolutely want to go to a friend's party and I have to resist. At the end of the conversation, you will produce a 10 lines sumary on how I did and what I could improve.", label: 'teen' },
    ]

    if (promptList && promptList.length > 0) {

        let op = promptList.map((val) => {
            return { value: val.prompt, label: val.label }
        })
        options.push(...op)
    }


    const options_gender = [
        { value: true, label: 'male' },
        { value: false, label: 'female' },
    ]

    const options_translation = [
        { label: "English", value: 'en' },
        { label: "French", value: 'fr' },
        { label: "Dutch", value: 'nl' },
        { label: "Spanish", value: 'es' },
        { label: "Italian", value: 'it' },
        { label: "German", value: 'gd' },
        { label: "Portuguese", value: 'pt' },
        { label: "Chinese", value: 'zh' },
        { label: "Japanese", value: 'ja' },
    ]

    /**
     * To start record
     */
    const start = () => {
        document.getElementById("button").style.disabled = true
        if (state.isBlocked) {
            console.log("Permission Denied");
        } else {
            Mp3Recorder.start()
                .then(() => {
                    setLayer(2)
                })
                .catch((e) => console.error(e));
        }
    };

    /**
     * To stop record and send the result into sendAudioFile function
     */
    const recordSend = async (timeID) =>
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
                clearInterval(timeID)
                var wavfromblob = new File([blob], "incomingaudioclip.wav");
                sendAudioFile(wavfromblob)
                setState({ isRecording: false });
            })
            .catch((e) => {
                clearInterval(timeID)
            });

    /**
    *To stop record 
    */

    const stop = async (timeID) =>
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
                setLayer(0)
                setState({ isRecording: false });
            })
            .catch((e) => {
                clearInterval(timeID)
            });

    /**
     *send file to server
     */
    const sendAudioFile = async (url) => {
        let data = new FormData();
        data.append("file", url);
        data.append("messageList", JSON.stringify(messageList))
        data.append("voice", voice)
        data.append("language", language.label)
        data.append("main_language", language.value)
        
        try {
            setLayer(1)
            let res = await axios.post(`${ServerUrl}api/transcribe`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            if (res.status === 200) {
                const dataUrl = `data:audio/mp3;base64,${res.data.buffer}`;

                // Set the data URL as the source for the audio element
                audioRef.current.src = dataUrl;

                // Play the audio

                layerChange(6)
                setMessageList(res.data.messageList)
                audioRef.current.play();
            } else {
                setLayer(0)
                toast.error("Request faild. Speech again", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false
                });
            }
        } catch (erro) {
            setLayer(0)
            toast.error("Request faild. Speech again", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false
            });
        }

    };

    const layerChange = (x) => {
        setLayer(x)
    }
      /**
     * 
     *send data to server
     */
    const sendPrompt = async (val) => {
        setMessageList([])
        if (val.value === "") {

        } else {
            let data = { prompt: val.value, messageList: [], voice: voice, language: language.label, main_language: language.value }

            try {
                setLayer(1)
                let res = await axios.post(`${ServerUrl}api/prompt`, data, {
                    headers: {
                        "Content-Type": 'application/json',
                    },
                })

                if (res.status === 200) {

                    const dataUrl = `data:audio/mp3;base64,${res.data.buffer}`;

                    // Set the data URL as the source for the audio element
                    audioRef.current.src = dataUrl;
                    layerChange(6)
                    setMessageList(res.data.messageList)
                    audioRef.current.play();
                } else {
                    setLayer(0)
                    toast.error("Request faild. Speech again", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: false
                    });
                }
            } catch (error) {
                setLayer(0)
                toast.error("Request faild. Speech again", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false
                });
            }
        }
    }

    const handleAudioEnd = () => {
        setTimeout(() => {
            setLayer(0)
        }, 500);
    }

    /**
     * to save pdf file
     */

    const save = () => {
        let str = ""
        if (messageList.length > 0) {

            for (let index = 1; index < messageList.length; index++) {
                str = str + messageList[index].role + "\n" + "\n" + messageList[index].content + "\n" + "\n"
            }
            const blob = new Blob([str], { type: 'text/plain' });
            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'whisper.txt';

            // Append the link to the body and trigger the download
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remove the link from the DOM
            document.body.removeChild(downloadLink);

        } else {
            toast.error("You don't have any data", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false
            });
        }

    }

    /**
     * generate summary
     */
    const summary = async () => {
        try {
            let data = { prompt: "give me summary", messageList: messageList, voice: voice, language: language.label, main_language: language.value }
            setLayer(1)
            let res = await axios.post(`${ServerUrl}api/summary`, data, {
                headers: {
                    "Content-Type": 'application/json',
                },
            })

            if (res.status === 200) {

                const dataUrl = `data:audio/mp3;base64,${res.data.buffer}`;

                // Set the data URL as the source for the audio element
                audioRef.current.src = dataUrl;
                layerChange(6)
                setMessageList(res.data.messageList)
                audioRef.current.play();
            } else {
                setLayer(0)
                toast.error("Request faild. Speech again", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false
                });
            }
        } catch (error) {
            setLayer(0)
            toast.error("Request faild. Speech again", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false
            });
        }
    }

    useEffect(() => {
        navigator.getUserMedia({ audio: true }, () => { }, () => {
            setLayer(5)
        },);
    }, [])

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             let res = await axios.post(`${ServerUrl}api/promptlist`)
    //             setPromptList(res.data)
    //         } catch (err) {
    //             // toast.error("Please check database for prompt", {
    //             //     position: toast.POSITION.TOP_RIGHT,
    //             //     autoClose: 5000,
    //             //     hideProgressBar: false
    //             // });
    //         }
    //     })()
    // }, [])

    return <div>
        <header className="App-header">
            <div className="header-train-select">
                <Select isDisabled={layer !== 0} onChange={(e) => { sendPrompt(e) }} options={options} />
            </div>
            <div className="header-gender-select">
                <Select isDisabled={layer !== 0}
                    onChange={(e) => { setVoice(e.value) }} options={options_gender} />
            </div>
            <div className="header-translation-select">
                <Select isDisabled={layer !== 0} onChange={(e) => { setLanguage(e) }} options={options_translation} />
            </div>
            <div className="header-button">
                {layer === 5 ?
                    <button className="button" onClick={() => { alert("Not found your Microphone. Please check it.") }}>
                        <span className="fa fa-microphone"></span>
                    </button>
                    :
                    <button className="button" id="button" onClick={start} disabled={layer !== 0}>
                        <span className="fa fa-microphone"></span>
                    </button>
                }
            </div>
        </header>
        <div className='container' >
            <div className='chatbox' ng-controller="MessageCtrl as chatMessage">
                <div className="chatbox__messages__user-message">
                    {messageList && messageList.length > 0 ?
                        messageList.map((val, index) => {
                            if (index !== 0)
                                return <div key={index} className="chatbox_wrapper">
                                    <div className={`chatbox__messages__user-message--ind-message ${index % 2 === 1 ? "message-left" : "message-right"}`}>
                                        <p>{val.content}
                                        </p>
                                    </div>
                                </div>
                            else
                                return ""
                        })
                        : ""}
                </div>
            </div>
            {layer === 1 ? <div className='mask'>
                <div className='loading'>
                    <div className='circle'><i className='fa fa-refresh'></i></div>
                    loading</div>
            </div> :
                ""}
            {layer === 2 ?
                <div className='voice-mask'>
                    <div className='voice-loading'>
                        <div className='voice-circle'><i className='fa fa-microphone'></i></div>
                        <div className="record">
                            <button className="button left" onClick={recordSend}>Send </button>
                            <button className="button right" onClick={stop}>Redo</button>
                        </div>
                    </div>
                </div> : ""}
            <div className="footer">
                <button className="button left" disabled={layer !== 0} onClick={summary}>Finish</button>
                <button className="button right" onClick={save}>Export</button>
            </div>
            <audio ref={audioRef} onEnded={handleAudioEnd} style={{ display: "hide" }} />
        </div>

        <ToastContainer />
    </div >
}

export default App